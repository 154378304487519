<div
  style="padding: 10px"
  *ngIf="{
    hasCruiseIdSelected: hasCruiseIdSelected$ | async,
    dict: labelDict$ | async
  } as resolvedData"
>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cruiseIdTemplateRef let-row="row">
          <span [title]="row | json"> {{row.cruiseId}} </span>
        </ng-template>

        <ng-template #prediction30DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence1 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise1]}} zu {{ row.confidence1 * 100
            | number:'1.0-0' }}%
          </div>
        </ng-template>

        <ng-template #prediction60DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence2 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise2]}} zu {{ row.confidence2 * 100
            | number:'1.0-0' }}%
          </div>
        </ng-template>

        <ng-template #prediction90DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence3 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise3]}} zu {{ row.confidence3 * 100
            | number:'1.0-0' }}%
          </div>
        </ng-template>

        <ng-template #cellDateAddedRef let-row="row">
          {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date
          :'shortTime'}}
        </ng-template>

        <ng-template #cellCabinTemplateRef let-row="row">
          {{row.cabin}}
          <div *ngIf="row?.dbCabin">
            <span [title]="row.dbCabin | json"> {{row.dbCabin.name}} </span>
          </div>
        </ng-template>

        <ng-container
          *ngIf="filterData.predictionType1 || filterData.predictionType2 || filterData.predictionType3; else SelectAtLeastOnePredictionTypeTemplateRef"
        >
          <ng-container *ngIf="tableConfig$ | async as tableConfig">
            <dynamic-table
              [config]="tableConfig"
              [hideFilters]="true"
              (rowClick)="clickRow()"
              (tableDraw)="globalLoadingService.release()"
            >
            </dynamic-table>
          </ng-container>
        </ng-container>
        <ng-template #SelectAtLeastOnePredictionTypeTemplateRef>
          <br />
          <br />
          Bitte wähle mindestens einen der drei Filter (Prognose 30, 60 oder 90
          Tage) aus, um die Daten anzuzeigen.
        </ng-template>
      </div>
    </div>
  </div>
</div>
