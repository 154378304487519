import { MatOptionSelectionChange } from '@angular/material/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import * as deepEqual from 'deep-equal';
import { cloneDeep, Dictionary } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { asyncScheduler, BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import * as moment from 'moment';

import {
  ItemFilterControlOptions,
  ItemFilterDataInterface,
  ItemFilterItemInterface,
  ItemFilterState
} from './item-filter-state.class';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

export interface INavigationStateService<T> {
  filterState?: UltraDynamicFilterState<T>;
  config?: UltraDynamicFilterConfig<T>;
  _dynamicFilterState?: UltraDynamicFilterStateMap<T>;
  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void;
}

export interface UltraDynamicFilterState<T> {
  filterStates: {
    [filterKey: string]: ItemFilterState;
  };
  getFilterData$?: Observable<T>;
  filterBehaviorSubject$?: BehaviorSubject<T>;
  filter$?: Observable<T>;
  resetFilters?: () => void;
}

export interface UltraDynamicFilterStateMap<T> {
  [routeKey: string]: UltraDynamicFilterState<T>;
}

export interface UltraDynamicFilterConfig<T> {
  route: string;
  filters: UltraDynamicFilterItemConfig[];
  transformFilters?: (fltr) => T;
  transformFilters$?: (fltr) => Observable<T>;
}

export interface UltraDynamicFilterItemConfig {
  id?: string;
  type:
    | 'INPUT_TEXT'
    | 'INPUT_NUMBER'
    | 'INPUT_NUMBER_WITH_STEPPER'
    | 'DROPDOWN'
    | 'MULTISELECT'
    | 'MULTISELECT_LEGACY'
    | 'SELECT_WITH_SEARCH'
    | 'MULTISELECT_WITH_SEARCH'
    | 'GROUP_MULTISELECT'
    | 'DATERANGE'
    | 'RADIO_SINGLE'
    | 'HIDDEN'
    | 'BUTTON'
    | 'BUTTON_GROUP'
    | 'DATEPICKER'
    | 'WEEK_PICKER'
    | 'TABS';
  matrixParameter: string;
  optionsFiltered$?: (fltr: any) => Observable<ItemFilterItemInterface[]>;
  options$?: Observable<ItemFilterItemInterface[]>;
  defaultValue?: string | string[];
  label: string;
  required?: boolean;
  iconName: string;
  ngModelChange?: ($event) => void;
  multiSelectOnSelectionChange?: ($event: MatOptionSelectionChange) => void;
  placeholder?: string;
  disabled?: boolean;
  emptyOption?: string;
  emptyOptionValue?: any;
  controlOptions?: ItemFilterControlOptions;
  dateTransformerArgs?: {
    inputFormat?: string;
    outputFormat?: string;
  };
  inputNumberWithStepperArgs?: {
    min?: number;
    max?: number;
  };
  // dateRangeCfg?: {
  //   densityCtrl?: Partial<DsDatepickerConfiguration>;
  //   format?: string;
  //   displayFormat?: string;
  // };
  dsSelectConfig?: {
    allowOptionsToWrap?: boolean;
  };
  showReset?: boolean;
  tooltipText?: string;
  disabledPlaceholder?: string;
}

export interface NavigationStateConfigMap<T> {
  [path: string]: NavigationStateConfig<T>;
}

interface NavigationStateConfig<T> {
  matrixParams?: MatrixParamAccessor<T>[];
  outlets?: {
    [name: string]: {
      [path: string]: {
        matrixParams?: MatrixParamAccessor<T>[];
      };
    };
  };
}

export interface MatrixParamAccessor<T> {
  name: keyof T;
  setter?(value: string);
  getter?(): string;
  defaultGetter?(): string;
  defaultSetter?(): void;
}

interface NavigationState {
  path: string;
  params?: Params;
  config?: NavigationStateConfig<any>;
  outlets?: {
    [name: string]: NamedOutletNavigationState;
  };
}

interface OutletNavigationState {
  [path: string]: NavigationState;
}

interface NamedOutletNavigationState extends NavigationState {
  outlet: string;
}

interface NavigationStateMap {
  [path: string]: NavigationState;
}

export interface NavigationWithFiltersMap {
  [p: string]: { key: string; type: 'single' | 'multi' };
}

export const GenerateDeepLinkWithFilters = (
  routePrefix: string,
  transformConfig: NavigationWithFiltersMap,
  filterParams
): string => {
  let paramsStringified = '';
  Object.keys(filterParams).forEach((key) => {
    if (filterParams[key] !== undefined && transformConfig?.[key]?.key) {
      const value = filterParams[key];
      if (value !== undefined) {
        paramsStringified += transformConfig[key].key + '=';
        switch (transformConfig[key].type) {
          case 'single':
            paramsStringified += Array.isArray(value) ? value[0] : value;
            break;
          case 'multi':
            paramsStringified += Array.isArray(value) ? value.join(',') : value;
            break;
        }
        paramsStringified += ';';
      }
    }
  });
  return `${routePrefix};${paramsStringified}`;
};

export const InitializeNavigationStateHandler = <T>(
  basePath: string,
  config: UltraDynamicFilterConfig<T>,
  router: Router
): {
  navigationStateHandler: NavigationStateHandler;
  dynamicFilterState: UltraDynamicFilterStateMap<T>;
} => {
  const dynamicParamsRouteConfig: NavigationStateConfigMap<any> = {};
  const filterDataObservables: Array<Observable<ItemFilterDataInterface>> = [];
  const dynamicFilterState: UltraDynamicFilterStateMap<T> = {};
  // it was an array before, keep array maybe we need it later
  [config].forEach((cfg) => {
    dynamicFilterState[cfg.route] = {
      filterStates: {},
      filterBehaviorSubject$: new BehaviorSubject<T>(null),
      resetFilters: () => {
        Object.keys(dynamicFilterState[cfg.route].filterStates).forEach(
          (matrixParam) =>
            dynamicFilterState[cfg.route].filterStates[
              matrixParam
            ].resetFilter()
        );
      }
    };
    dynamicFilterState[cfg.route].filter$ = dynamicFilterState[
      cfg.route
    ].filterBehaviorSubject$.pipe(
      filter((x) => !!x),
      debounceTime(500),
      shareReplay(1)
    );
    // dynamically construct filter states
    cfg.filters.forEach((filterCfg) => {
      let options$: Observable<ItemFilterItemInterface[]>;
      if (filterCfg.options$) {
        // regular options
        options$ = filterCfg.options$;
      } else if (filterCfg.optionsFiltered$) {
        // options dependent on filter$
        options$ = dynamicFilterState[cfg.route].filter$.pipe(
          switchMap((filterValue) => filterCfg.optionsFiltered$(filterValue)),
          shareReplay(1)
        );
      }
      dynamicFilterState[cfg.route].filterStates[filterCfg.matrixParameter] =
        new ItemFilterState(
          {
            availableItems: null,
            defaultValue: filterCfg.defaultValue
          },
          {
            selectedIds: filterCfg.defaultValue
          },
          options$ ?? null,
          filterCfg.controlOptions ?? null
        );
      filterDataObservables.push(
        dynamicFilterState[cfg.route].filterStates[filterCfg.matrixParameter]
          .filterData$
      );
    });

    dynamicFilterState[cfg.route].getFilterData$ = combineLatest(
      Object.keys(dynamicFilterState[cfg.route].filterStates).map(
        (k) => dynamicFilterState[cfg.route].filterStates[k].filterData$
      )
    ).pipe(
      switchMap((filterStates) => {
        const filterData = {};
        cfg.filters.forEach((filterEntry, filterIndex) => {
          if (filterStates[filterIndex]?.selectedIds) {
            filterData[filterEntry.matrixParameter] =
              filterStates[filterIndex].selectedIds;
          }
        });
        if (cfg.transformFilters$) {
          return cfg.transformFilters$(filterData);
        } else {
          return of(cfg.transformFilters(filterData));
        }
      }),
      debounceTime(0),
      distinctUntilChanged((a, b) => deepEqual(a, b)),
      tap((filterData) =>
        dynamicFilterState[cfg.route].filterBehaviorSubject$.next(filterData)
      )
    );
    // dynamically construct paramsRouteConfig
    dynamicParamsRouteConfig[cfg.route] = {
      matrixParams: cfg.filters.map((filterCfg) => {
        const matrixParamAccessor: MatrixParamAccessor<any> = {
          name: filterCfg.matrixParameter,
          setter:
            dynamicFilterState[cfg.route].filterStates[
              filterCfg.matrixParameter
            ].getFilterDataSetter(),
          getter:
            dynamicFilterState[cfg.route].filterStates[
              filterCfg.matrixParameter
            ].getFilterDataGetter()
        };
        return matrixParamAccessor;
      })
    };
  });
  return {
    navigationStateHandler: new NavigationStateHandler(
      router,
      basePath,
      dynamicParamsRouteConfig,
      [combineLatest(filterDataObservables)]
    ),
    dynamicFilterState: dynamicFilterState
  };
};

export class NavigationStateHandler implements INavigationStateService<any> {
  private _stateInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  private _states: NavigationStateMap = {};

  private _currentState: NavigationState;

  private _stateStack: NavigationState[] = [];

  private _outletsProcessed: string[];

  private _navigateParameters$ = new BehaviorSubject(null);

  private overlayFilterInitialed: boolean;

  get currentState() {
    return { ...this._currentState };
  }

  constructor(
    protected _router: Router,
    private _basePath: string,
    private _navigationStateConfig: NavigationStateConfigMap<any>,
    private _navigationStateUpdatesProviders: Observable<any>[]
  ) {
    const notifiers = _navigationStateUpdatesProviders;
    notifiers.unshift(this._stateInitialized$.pipe(distinctUntilChanged()));
    combineLatest(notifiers)
      .pipe(
        observeOn(asyncScheduler),
        filter(([initialized]) => initialized)
      )
      .subscribe(() => this._updateUrl());
    this._navigateParameters$
      .pipe(
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        filter((param) => !!param)
      )
      .subscribe((param) => {
        this._router.routeReuseStrategy.shouldReuseRoute = (future, curr) => {
          const futureBaseUrl =
            (future as any)?._routerState?.url?.split(';')[0] ?? '';
          const currBaseUrl =
            (curr as any)?._routerState?.url?.split(';')[0] ?? '';
          return futureBaseUrl === currBaseUrl;
        };

        this._router
          .navigate(param.cmd, {
            ...param.extras,
            replaceUrl: !this.overlayFilterInitialed
          })
          .then(() => {
            this.overlayFilterInitialed = true;
          });
      });
  }

  private _createUrlMatrixParams(s: NavigationState): [Params, boolean] {
    let changed = false;
    const p = { ...s.params };
    if (s.config && s.config.matrixParams) {
      s.config.matrixParams.forEach(
        (mp: MatrixParamAccessor<any> & { name: string }) => {
          if (mp.getter) {
            const v = mp.getter();
            if (v !== p[mp.name]) {
              // TODO?: check for default value of v
              changed = true;
              if (v === null) {
                delete p[mp.name];
              } else {
                p[mp.name] = v;
              }
            }
          }
        }
      );
    }
    return [p, changed];
  }

  private _getNavigationPath(path: string, params: Params = {}): string[] {
    const res = [];
    if (path.indexOf('/') + 1) {
      path.match(/([^/]+)/g).forEach((sk) => {
        if (sk.indexOf(':') === 0) {
          const k = sk.replace(/:/g, '');
          if (params[k]) {
            res.push(params[k]);
          } else {
            console.error(
              'NavigationState Error',
              k,
              'does not exist in params data',
              params,
              path
            );
          }
        } else {
          res.push(sk);
        }
      });
    } else {
      res.push(path);
    }
    return res;
  }

  private _updateUrl() {
    let changed = false;
    const s = this._currentState,
      cmd: Array<any> = this._getNavigationPath(s.path, s.params);
    cmd.unshift(this._basePath);
    // add Matrix params
    let [p, c] = this._createUrlMatrixParams(s);
    changed = changed || c;
    cmd.push(p);

    const outlets = {};
    Object.keys(s.outlets).forEach((k) => {
      const os = s.outlets[k];
      if (os && os.path) {
        outlets[k] = this._getNavigationPath(os.path, os.params);
        [p, c] = this._createUrlMatrixParams(os);
        changed = changed || c;
        outlets[k].push(p);
      } else {
        outlets[k] = null;
        changed = changed || c;
      }
    });
    cmd.push({ outlets: outlets });

    const extras: NavigationExtras = {
      queryParamsHandling: 'preserve'
    };

    if (changed) {
      this._navigateParameters$.next({ cmd, extras });
    }
  }

  private _setPrimaryState(path: string, params: Params): NavigationState {
    if (!this._states[path]) {
      this._states[path] = {
        path: path,
        params: params,
        config: this._navigationStateConfig[path],
        outlets: {}
      };
    } else {
      this._states[path].params = params;
      // this._states[path].outlets = {}; // FIXME: really reset outlets?
    }
    this._currentState = this._states[path];
    return this._currentState;
  }

  private _addOutletState(
    outlet: string,
    path: string,
    params: Params
  ): NamedOutletNavigationState {
    const pc = this._currentState.config,
      pcOutlet =
        pc && pc.outlets && pc.outlets[outlet] ? pc.outlets[outlet] : null;
    this._currentState.outlets[outlet] = {
      outlet: outlet,
      path: path,
      params: params,
      config: pcOutlet[path]
    } as NamedOutletNavigationState;
    return this._currentState.outlets[outlet];
  }

  // @ts-ignore
  private _toNavigation(
    primary: { path: string; params?: Params },
    outlets: { [name: string]: { path: string; params?: Params } } = {},
    // state?: Dictionary<any, string>
    state?: any
  ) {
    const cmd: Array<any> = this._getNavigationPath(
      primary.path,
      primary.params
    );
    cmd.unshift(this._basePath);
    // add matrix params
    const s = this._states[primary.path];
    if (s) {
      cmd.push(this._createUrlMatrixParams(s)[0]);
    }
    // Outlets:
    const o = {};
    Object.keys(outlets).forEach((k) => {
      const outletState = s?.outlets[k];
      if (outlets[k].path) {
        o[k] = this._getNavigationPath(outlets[k].path, outlets[k].params);
        // add matrix params
        if (outletState && outletState[outlets[k].path]) {
          cmd.push(
            this._createUrlMatrixParams(outletState[outlets[k].path])[0]
          );
        }
      } else {
        if (outletState) {
          delete s.outlets[k]; // remove from state, otherwise not detected
        }
        o[k] = null;
      }
    });
    cmd.push({ outlets: o });
    const extras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      state
    };
    this._router.navigate(cmd, extras).then(() => {
      this.overlayFilterInitialed = false;
      this._navigateParameters$.next(null);
    });
  }

  private _toOutlet(
    primary: { path: string; params?: Params },
    outlets: { [name: string]: { path: string; params?: Params } } = {},
    skipLocationChange: boolean
  ) {
    const cmd: Array<any> = this._getNavigationPath(
      primary.path,
      primary.params
    );
    cmd.unshift(this._basePath);
    cmd.push(primary.params);
    cmd.push({
      outlets: Object.keys(outlets).reduce((result, name) => {
        const outlet = outlets[name];
        result[name] = [outlet.path];
        if (outlet.params) {
          result[name].push(outlet.params);
        }
        return result;
      }, {})
    });

    const extras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      skipLocationChange
    };
    this._router.navigate(cmd, extras).then(() => false);
  }

  private _pushStateStack() {
    this._stateStack.push(cloneDeep(this._currentState));
  }

  private _popStateStack(): {
    primary?: NavigationState;
    outlets?: OutletNavigationState;
  } {
    const parentState = this._stateStack.pop();
    return state2primaryAndOutlet(parentState);
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    if (this._stateInitialized$.getValue() !== false) {
      this._router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => {
          // remove outlets not processed (e.g. removing outlet by router is not triggered)
          const outletNames = Object.keys(this._currentState.outlets);
          outletNames.forEach((o) => {
            if (!this._outletsProcessed.includes(o)) {
              delete this._currentState.outlets[o];
            }
          });
          this._stateInitialized$.next(true);
        });
      this._stateInitialized$.next(false);
    }
    const rc = routeSnapShot.routeConfig;
    let s: NavigationState;
    if (routeSnapShot.outlet === 'primary') {
      s = this._setPrimaryState(rc.path, routeSnapShot.params);
      this._outletsProcessed = [];
    } else {
      s = this._addOutletState(
        routeSnapShot.outlet,
        rc.path,
        routeSnapShot.params
      );
      this._outletsProcessed.push(routeSnapShot.outlet);
    }

    if (s.config && s.config.matrixParams) {
      s.config.matrixParams.forEach(
        (mp: MatrixParamAccessor<any> & { name: string }) => {
          if (mp.defaultSetter) {
            mp.defaultSetter();
          }
          if (mp.setter && mp.getter) {
            if (s.params.hasOwnProperty(mp.name)) {
              const v = mp.getter();
              if (v !== s.params[mp.name]) {
                mp.setter(s.params[mp.name]);
                return;
              }
            } else {
              if (mp.defaultGetter) {
                mp.setter(mp.defaultGetter());
              }
            }
          }
        }
      );
    }
  }

  navigateByParams(primary, outlets) {
    this._pushStateStack();
    this._toOutlet(primary, outlets, true);
  }

  // @ts-ignore
  navigateTo(primary, outlets, state?: Dictionary<any, string>) {
    this._pushStateStack();
    this._toNavigation(primary, outlets, state);
  }

  closeAndNavigateTo(primary, outlets) {
    const state = this._popStateStack();
    if (!state.outlets) {
      this._toNavigation(primary, outlets);
    } else {
      this._toOutlet(state.primary, state.outlets, false);
    }
  }

  backOutlet(outletName: string): boolean {
    const currentStateOutlet = this.currentState.outlets[outletName];

    let stateIdx = this._stateStack.length - 1;
    while (
      stateIdx >= 0 &&
      deepEqual(
        this._stateStack[stateIdx].outlets[outletName],
        currentStateOutlet
      )
    ) {
      stateIdx--;
    }
    if (stateIdx >= 0) {
      const states = state2primaryAndOutlet(this._stateStack[stateIdx]);
      let { outlets } = states;
      if (!outlets) {
        outlets = {};
      }
      if (!outlets[outletName]) {
        outlets[outletName] = { path: null };
      }
      this._stateStack.splice(stateIdx);
      this._toNavigation(states.primary, outlets);
      return true;
    }
    return false;
  }
}

export const ToStringArray = (arr: Array<string> | string): Array<string> => {
  let newArr;
  if (!Array.isArray(arr)) {
    if (arr?.indexOf(',')) {
      newArr = arr.split(',');
    } else {
      newArr = [arr];
    }
  } else {
    newArr = arr;
  }
  return newArr;
};

export const ParseDateRangeMoments = (
  dateRangeFilterState
): { dateStart: moment.Moment; dateEnd: moment.Moment } => {
  const splittedDateRangeString = (
    Array.isArray(dateRangeFilterState.selectedIds)
      ? dateRangeFilterState.selectedIds[0]
      : (dateRangeFilterState.selectedIds as string)
  )?.split('_');
  if (splittedDateRangeString?.length) {
    return {
      dateStart: moment(splittedDateRangeString[0], 'YYYY-MM-DD'),
      dateEnd: moment(splittedDateRangeString[1], 'YYYY-MM-DD')
    };
  } else {
    return null;
  }
};

/**
 * Initializes Dictionary of FormGroups for DateRanges. It also looks at the current filter state and extracts the moment dates
 * out of it and puts them into the formgroup so the form control is initialized with the correct date. It is a promise because we have to read the filterData$.
 */
export const DynamicDateRangeFormGroups = async (
  filterStateService: INavigationStateService<any>,
  filters: UltraDynamicFilterItemConfig[]
): Promise<{ [key: string]: FormGroup }> => {
  const dynamicDateRangeFormGroups: { [key: string]: FormGroup } = {};
  const dateRangeFilterConfigs = filters.filter((x) => x.type === 'DATERANGE');
  for (const dateRangeFilterConfig of dateRangeFilterConfigs) {
    let startMoment: moment.Moment = null;
    let endMoment: moment.Moment = null;
    try {
      const dateRangeFilterState =
        await filterStateService.filterState.filterStates[
          dateRangeFilterConfig.matrixParameter
        ].filterData$
          .pipe(take(1))
          .toPromise();
      if (dateRangeFilterState?.selectedIds?.length) {
        const dateRange = ParseDateRangeMoments(dateRangeFilterState);
        if (dateRange?.dateStart && dateRange.dateEnd) {
          startMoment = dateRange.dateStart;
          endMoment = dateRange.dateEnd;
        }
      }
    } catch (e) {
      console.log(
        `Failed to initialize matrixParameter '${dateRangeFilterConfig.matrixParameter}' of type 'DATERANGE'. Please contact support@mein-schiffberater.com`
      );
    }
    dynamicDateRangeFormGroups[dateRangeFilterConfig.matrixParameter] =
      new FormGroup({
        start: new FormControl(startMoment),
        end: new FormControl(endMoment)
      });
  }
  return dynamicDateRangeFormGroups;
};

export const ParseDateRange = (
  dateRangeFilterState
): { dateStart: Date; dateEnd: Date } => {
  const splittedDateRangeString = (
    Array.isArray(dateRangeFilterState.selectedIds)
      ? dateRangeFilterState.selectedIds[0]
      : (dateRangeFilterState.selectedIds as string)
  )?.split('_');
  if (splittedDateRangeString?.length) {
    return {
      dateStart: moment(splittedDateRangeString[0], 'YYYY-MM-DD').toDate(),
      dateEnd: moment(splittedDateRangeString[1], 'YYYY-MM-DD').toDate()
    };
  } else {
    return null;
  }
};

export const DynamicDateRangeLabelObservables = (
  filterStateService: INavigationStateService<any>,
  filters: UltraDynamicFilterItemConfig[],
  datePipe: DatePipe
): { [key: string]: Observable<string> } => {
  const dynamicDateRanges: { [key: string]: Observable<string> } = {};
  filters
    .filter((x) => x.type === 'DATERANGE')
    .forEach((dateRangeFltr) => {
      dynamicDateRanges[dateRangeFltr.matrixParameter] =
        filterStateService.filterState.filterStates[
          dateRangeFltr.matrixParameter
        ].filterData$.pipe(
          map((dateRangeFilterState) => {
            let dateRangeLabel = [];
            const dateRange = ParseDateRange(dateRangeFilterState);
            if (dateRange?.dateStart) {
              dateRangeLabel.push(
                datePipe.transform(dateRange.dateStart, 'dd.MM.yyyy') + ' -'
              );
            }
            if (dateRange?.dateEnd) {
              dateRangeLabel.push(
                datePipe.transform(dateRange.dateEnd, 'dd.MM.yyyy')
              );
            }
            if (dateRangeLabel.length === 0 && dateRangeFltr?.placeholder) {
              dateRangeLabel.push(dateRangeFltr?.placeholder);
            }
            return dateRangeLabel.join(' ');
          })
        );
    });
  return dynamicDateRanges;
};

function state2primaryAndOutlet(navigationState: NavigationState): {
  primary?: NavigationState;
  outlets?: OutletNavigationState;
} {
  return !navigationState
    ? {}
    : Object.assign(
        {
          primary: {
            path: navigationState.path,
            params: navigationState.params
          }
        },
        !Object.keys(navigationState.outlets || {}).length
          ? {}
          : {
              outlets: Object.keys(navigationState.outlets || {}).reduce(
                (result, key) => {
                  const state = navigationState.outlets[key];
                  result[key] = { path: state.path, params: state.params };
                  return result;
                },
                {}
              )
            }
      );
}
