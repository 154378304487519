import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStatePricePredictionsService } from './filter-state-price-predictions.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { AdminService } from '../../../apis/msb/services/admin.service';
import {
  DynamicTableComponent,
  UltraDynamicTableConfig
} from '../../../shared/components/dynamic-table/dynamic-table.component';
import { DatabaseModelCabin } from '../../../apis/msb/models/database-model-cabin';

@Component({
  selector: 'app-manage-price-predictions',
  templateUrl: './price-predictions.page.html',
  styleUrls: ['./price-predictions.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricePredictionsComponent implements OnInit {
  @ViewChild('cruiseIdTemplateRef') cruiseIdTemplateRef: TemplateRef<any>;
  @ViewChild('cellDateAddedRef') cellDateAddedRef: TemplateRef<any>;
  @ViewChild('cellCabinTemplateRef') cellCabinTemplateRef: TemplateRef<any>;

  @ViewChild('prediction30DaysTemplateRef')
  prediction30DaysTemplateRef: TemplateRef<any>;
  @ViewChild('prediction60DaysTemplateRef')
  prediction60DaysTemplateRef: TemplateRef<any>;
  @ViewChild('prediction90DaysTemplateRef')
  prediction90DaysTemplateRef: TemplateRef<any>;

  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly cabinsMap$: Observable<{
    [key: string]: DatabaseModelCabin;
  }> = this.filterStateService.allCabins$.pipe(
    map((cabins) => {
      const dict = {};
      try {
        cabins.data.forEach((c) => {
          dict[`${c?.shipId}_${c?.cabinCode}`] = c;
        });
      } catch (e) {}
      return dict;
    }),
    shareReplay()
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    debounceTime(100),
    tap((fltr) => {
      if (fltr.predictionType1) {
        (document.querySelector('#predictionType2') as any).style.display =
          'none';
        (document.querySelector('#predictionType3') as any).style.display =
          'none';
      } else if (fltr.predictionType2) {
        (document.querySelector('#predictionType1') as any).style.display =
          'none';
        (document.querySelector('#predictionType3') as any).style.display =
          'none';
      } else if (fltr.predictionType3) {
        (document.querySelector('#predictionType1') as any).style.display =
          'none';
        (document.querySelector('#predictionType2') as any).style.display =
          'none';
      } else {
        (document.querySelector('#predictionType1') as any).style.display =
          'block';
        (document.querySelector('#predictionType2') as any).style.display =
          'block';
        (document.querySelector('#predictionType3') as any).style.display =
          'block';
      }
    }),
    shareReplay()
  );

  readonly tableConfig$: Observable<UltraDynamicTableConfig> =
    this.filter$.pipe(
      map((fltr) => {
        const columns = [
          {
            prop: 'cruiseId',
            name: 'CruiseId',
            cellTemplate: this.cruiseIdTemplateRef
          },
          { prop: 'ship', name: 'Schiff', sortable: false },
          { prop: 'route', name: 'Abreisetermin', sortable: false },
          {
            prop: 'cabin',
            name: 'Kabine',
            cellTemplate: this.cellCabinTemplateRef
          },
          { prop: 'priceModel', name: 'Tarif' }
        ];

        if (fltr.predictionType1) {
          columns.push({
            prop: 'priceCruise1',
            name: 'Prognose 30 Tage',
            cellTemplate: this.prediction30DaysTemplateRef
          });
        } else if (fltr.predictionType2) {
          columns.push({
            prop: 'priceCruise2',
            name: 'Prognose 60 Tage',
            cellTemplate: this.prediction60DaysTemplateRef
          });
        } else if (fltr.predictionType3) {
          columns.push({
            prop: 'priceCruise3',
            name: 'Prognose 90 Tage',
            cellTemplate: this.prediction90DaysTemplateRef
          });
        }

        columns.push({
          prop: 'dateAdded',
          name: 'Prognose erstellt am',
          cellTemplate: this.cellDateAddedRef
        });

        const cfg: UltraDynamicTableConfig = {
          defaultOrder: 'id',
          defaultOrderDirection: 'desc',
          pageLength: fltr.pageLength,
          columns,
          fetchData: this.fetchData()
        };
        return cfg;
      })
    );

  readonly hasCruiseIdSelected$: Observable<boolean> = this.filter$.pipe(
    map((fltr) => !!fltr?.cruiseId?.length),
    shareReplay(1)
  );

  readonly selectedTrip$ = this.filter$.pipe(
    map((fltr) => !!fltr?.cruiseId?.length),

    shareReplay(1)
  );

  readonly labelDict$ = this.filterStateService.predictionTypeOpts$.pipe(
    map((arr) => {
      const dict = {};
      arr.forEach((item) => {
        dict[item.id] = item.label;
      });
      return dict;
    }),
    shareReplay(1)
  );

  constructor(
    readonly filterStateService: FilterStatePricePredictionsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) =>
      this.filter$.pipe(
        tap((fltr) => {
          request.customFilter = [];
          if (fltr?.ship) {
            const customFilterKey = 'ship';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.ship + '-'
              });
            }
          }
          if (fltr?.cruiseId) {
            const customFilterKey = 'cruiseId';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.cruiseId
              });
            }
          }
          if (fltr?.predictionType1) {
            const customFilterKey = 'predictionType1';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType1
              });
            }
          }
          if (fltr?.predictionType2) {
            const customFilterKey = 'predictionType2';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType2
              });
            }
          }
          if (fltr?.predictionType3) {
            const customFilterKey = 'predictionType3';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType3
              });
            }
          }
          if (fltr?.priceModel) {
            const customFilterKey = 'priceModel';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.priceModel
              });
            }
          }
          // if (fltr?.cabinType) {
          //   const customFilterKey = 'cabinType';
          //   if (!request.customFilter.some((x) => x.key === customFilterKey)) {
          //     request.customFilter.push({
          //       key: customFilterKey,
          //       value: fltr?.cabinType
          //     });
          //   }
          // }
          if (fltr?.cabin) {
            const customFilterKey = 'cabin';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.cabin
              });
            }
          }
        }),
        switchMap(() =>
          this.adminService
            .streamPricePredictions({
              body: request
            })
            .pipe(
              switchMap((response) => {
                return combineLatest([
                  this.filterStateService.filterState.filterStates.cruiseId
                    .filterSettings$,
                  this.filterStateService.filterState.filterStates.ship
                    .filterSettings$,
                  this.filter$,
                  this.cabinsMap$
                ]).pipe(
                  filter(
                    ([cruiseIdOpts, shipOpts]) =>
                      !!cruiseIdOpts?.availableItems?.length &&
                      !!shipOpts?.availableItems?.length
                  ),
                  take(1),
                  map(([cruiseIdOpts, shipOpts, fltr, cabinsMap]) => {
                    if (!!response?.data?.length) {
                      response.data.forEach((row) => {
                        row['route'] =
                          cruiseIdOpts?.availableItems?.find(
                            (c) => c?.id === row?.cruiseId
                          )?.label ?? '';
                        row['ship'] =
                          shipOpts?.availableItems?.find(
                            (c) =>
                              c?.id === row?.cruiseId?.split('-')?.[0] ?? ''
                          )?.label ?? '';
                        row['shipId'] = row?.cruiseId?.split('-')?.[0] ?? 0;
                        const dbCabin =
                          cabinsMap?.[`${row['shipId']}_${row.cabin}`];
                        if (dbCabin) {
                          row['dbCabin'] = dbCabin;
                        }
                      });
                      if (fltr?.cabinType) {
                        response.data = response.data.filter(
                          (x) =>
                            `${x?.['dbCabin']?.cabinType}` ===
                            `${fltr.cabinType}`
                        );
                      }
                    }
                    return response;
                  })
                );
              })
            )
        )
      );
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }
}
